import React, { useEffect } from "react";

import commonFunction from "../../../../lib/utils/common";

export default function FlightAutoComplete(props) {
  const onClickHandler = (e) => {
    document.querySelector("body").style.overflow = props?.isMobile
      ? "hidden"
      : "auto";

    // if (props.expandAutoCompleteBool === true) props.isAutoCompleteExpand(false)
    // else if (props.expandAutoCompleteBool === false) {
    props.onclickFlightAutoComplete();
    // props.isAutoCompleteExpand(true)
    // }
  };

  const selectListClickHandler = (element) => {
    document.querySelector("body").style.overflow =
      !props.expandAutoCompleteBool && props?.isMobile ? "hidden" : "auto";
    // console.log("aarti3")
    props.onClickSourceAndDestination(
      element.CITY_NAME,
      element.AIRPORT_CODE,
      element.AIRPORT_NAME,
      element
    );
    // props.isAutoCompleteExpand(false)
  };

  return (
    <div
      className={props.styles.flight_search_wid_inputfield}
      ref={props.listWrapper}
      id={`Flight_${props.label}_${props.id}`}
    >
      <label>{props.label}</label>
      {/* <img src={props.leftIcon} className={props.styles.flight_search_left_icon} /> */}
      <div
        className={`${props.styles.autocomplet_wrap} ${
          props.isMobile && props.selectedTripType == "Multicity"
            ? props.styles.multicity_wrap
            : ""
        }`}
      >
        {/* <span className={props.styles.flight_search_left_icon}>
                {props.leftIcon}
            </span> */}

        <input
          type="text"
          autoComplete={"off"}
          id="myTextField1"
          data-id={props?.dataid}
          tabindex="1"
          placeholder={
            !commonFunction.isValueEmpty(props.placholder)
              ? props.placholder
              : ""
          }
          className={props.styles.search_widget_input_new}
          value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
          onChange={(event) => {
            props.onChangeSourceAndDestination(
              event.target.value.replace(/[^a-zA-Z\s]/g, "")
            );
          }}
          onClick={onClickHandler}
          onFocusCapture={onClickHandler}
        />
      </div>
      <div className={props.styles.flight_airlineName}>
        {!commonFunction.isValueEmpty(props.subTitle) ? props.subTitle : ""}
      </div>
      {/* <div className={props.styles.flight_ptext}>
            <p>{!commonFunction.isValueEmpty(props.airPortName) ? props.airPortName : ""}</p>
            </div> */}
      {!commonFunction.isValueEmpty(props.isError) && props.isError === true ? (
        <div
          className={`${
            props.isModifySearch
              ? props.styles.error_srp
              : props.styles.flight_search_error_message
          }
          ${
            props.selectedTripType == "Multicity" && !props?.isMobile
              ? props.styles.multicity_error
              : ""
          }`}
        >
          {props.selectedTripType == "Multicity" && props.isMobile ? null : (
            <span>
              {!commonFunction.isValueEmpty(props.errorMessage)
                ? props.errorMessage
                : "Invalid field"}
            </span>
          )}
        </div>
      ) : null}
      {props?.state?.isMultiCitySearch === true &&
      props.state.selectedDepartureIndex === props.index &&
      props.label === "From" &&
      props.expandAutoCompleteBool === true ? (
        <div
          ref={props.listWrapper}
          tabIndex="2"
          className={props.styles.flight_autocomplete_value}
          id={`Flight_${props.label}_${props.id}`}
        >
          {!commonFunction.isValueEmpty(props.autoCompleteData) &&
            props.autoCompleteData.length > 0 &&
            props.autoCompleteData.map((element, i) => {
              return (
                <div
                  id="flightData"
                  key={"autoCompleteData" + (i + 1)}
                  className={props.styles.flight_autocomplete_element}
                  onClick={() => {
                    selectListClickHandler(element);
                  }}
                  tabIndex="2"
                >
                  <div className={props.styles.fltg_icon}>
                    <svg
                      className={props.styles.plane_img}
                      width="38"
                      height="27"
                    >
                      <path
                        fill-rule="evenodd"
                        stroke="#4B4B4B"
                        fill="#E4E4E4"
                        d="m29.85 4.74-7.135 3.081L15.65 6.14a1.495 1.495 0 0 0-.949.105l-1.652.806c-.492.245-.808.735-.773 1.261 0 .561.316 1.016.808 1.26l3.374 1.541-5.377 2.733-4.464-1.541a1.839 1.839 0 0 0-1.3.07c-.528.245-.914.77-.984 1.331-.071.595.14 1.156.597 1.541l4.358 3.642c.387.316.914.42 1.406.245l8.436-3.081-1.547 5.007c-.211.666 0 1.402.527 1.857.317.28.738.42 1.125.42.246 0 .527-.07.773-.174l1.371-.666c.351-.175.633-.456.808-.806l3.937-8.231 6.467-3.011a3.183 3.183 0 0 0 1.617-1.822c.281-.806.211-1.646-.141-2.416A3.184 3.184 0 0 0 29.85 4.74z"
                      ></path>
                    </svg>
                    {/* <svg className={props.styles.plane_img} width="18" height="17">
                                    <path fill-rule="evenodd" fill="#7F747C" d="M23.819 6.123 8.442 12.885a1.015 1.015 0 0 1-.331.07.939.939 0 0 1-.474-.151L.924 8.43c-.126-.475-.225-.596-.285-.736-.179-.415.013-.897 1.031-1.076L2.964 5.8c.216-.1.466-.1.682 0l4.768 3.16 4.689-2.67-6.46-3.581a.822.822 0 0 1-.376-.398c.583-.413.004-.897.417-1.08l2.253-.99a.79.79 0 0 1 .589-.029L19.64 3.401l2.327-1.282a2.296 2.296 0 0 1 1.807 0c1.75.676 1.754 3.292.045 4.004zm-.695-2.505a.67.67 0 0 0-.52 0L19.456 5.5c-.191.42.034-.393-.609-.04L9.305 1.857l-.389.433 6.456 3.063a.82.82 0 0 1 .221 1.294 1.02 1.02 0 0 1-.266.184L8.72 9.746a.866.866 0 0 1-.266.061c-.14.011-.281-.014.006-.074L3.278 7.441l.052.098 4.875 3.66 14.919-6.587a.54.54 0 0 0 .265-.266c.128.374.009-.6-.265-.728zM1.398 15.35h22.895a.818.818 0 0 1 0 1.637H1.398a.819.819 0 0 1 0-1.637z"/>
                                </svg> */}
                  </div>
                  <div className={props.styles.flight_autocomplete_name}>
                    {element.CITY_NAME}, {element.COUNTRY_NAME}
                    <span className={props.styles.flight_autoCityfull}>
                      {element.AIRPORT_NAME}
                    </span>
                  </div>
                  <div className={props.styles.flight_autocomplete_tag}>
                    {element.AIRPORT_CODE}
                  </div>
                </div>
              );
            })}
        </div>
      ) : null}
      {props?.state?.isMultiCitySearch === true &&
      props.state.selectedGoingToIndex === props.index &&
      props.label === "To" &&
      props.expandAutoCompleteBool === true ? (
        <div
          ref={props.listWrapper}
          tabIndex="2"
          className={props.styles.flight_autocomplete_value}
          id={`Flight_${props.label}_${props.id}`}
        >
          {!commonFunction.isValueEmpty(props.autoCompleteData) &&
            props.autoCompleteData.length > 0 &&
            props.autoCompleteData.map((element, i) => {
              return (
                <div
                  id="flightData"
                  key={"autoCompleteData" + (i + 1)}
                  className={props.styles.flight_autocomplete_element}
                  onClick={() => {
                    selectListClickHandler(element);
                  }}
                  tabIndex="2"
                >
                  <div className={props.styles.fltg_icon}>
                    <svg
                      className={props.styles.plane_img}
                      width="38"
                      height="27"
                    >
                      <path
                        fill-rule="evenodd"
                        stroke="#4B4B4B"
                        fill="#E4E4E4"
                        d="m29.85 4.74-7.135 3.081L15.65 6.14a1.495 1.495 0 0 0-.949.105l-1.652.806c-.492.245-.808.735-.773 1.261 0 .561.316 1.016.808 1.26l3.374 1.541-5.377 2.733-4.464-1.541a1.839 1.839 0 0 0-1.3.07c-.528.245-.914.77-.984 1.331-.071.595.14 1.156.597 1.541l4.358 3.642c.387.316.914.42 1.406.245l8.436-3.081-1.547 5.007c-.211.666 0 1.402.527 1.857.317.28.738.42 1.125.42.246 0 .527-.07.773-.174l1.371-.666c.351-.175.633-.456.808-.806l3.937-8.231 6.467-3.011a3.183 3.183 0 0 0 1.617-1.822c.281-.806.211-1.646-.141-2.416A3.184 3.184 0 0 0 29.85 4.74z"
                      ></path>
                    </svg>
                  </div>
                  <div className={props.styles.flight_autocomplete_name}>
                    {element.CITY_NAME}, {element.COUNTRY_NAME}
                    <span className={props.styles.flight_autoCityfull}>
                      {element.AIRPORT_NAME}
                    </span>
                  </div>
                  <div className={props.styles.flight_autocomplete_tag}>
                    {element.AIRPORT_CODE}
                  </div>
                </div>
              );
            })}
        </div>
      ) : null}
      {props?.state?.isMultiCitySearch === false &&
      props.expandAutoCompleteBool === true ? (
        <>
          <div
            ref={props.listWrapper}
            tabIndex="2"
            className={props.styles.flight_autocomplete_value}
            id={`Flight_${props.label}_${props.id}`}
          >
            {/* <svg className={props.styles.arrow_wiget} width="151" height="101" viewBox="0 0 151 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M75.5 1.3811L140.885 76.0953H10.1151L75.5 1.3811Z" fill="white"/>
                    <line y1="-1.5" x2="100.715" y2="-1.5" transform="matrix(0.666347 -0.745642 0.939264 0.343196 9.5873 76.4784)" stroke="#2FC7C9" stroke-width="3"/>
                    <path d="M76.0269 1.19861L140.214 76.0953" stroke="#2FC7C9" stroke-width="3"/>
                    </svg> */}

            {!props?.isMobile && (
              <div className={props.styles.flight_InputVal}>
                <span className={props.styles.search_Autoicon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="14"
                  >
                    <defs>
                      <filter id="6waepprpsa">
                        <feFlood
                          flood-color="#979797"
                          flood-opacity="1"
                          result="floodOut"
                        ></feFlood>
                        <feComposite
                          operator="atop"
                          in="floodOut"
                          in2="SourceGraphic"
                          result="compOut"
                        ></feComposite>
                        <feBlend in="compOut" in2="SourceGraphic"></feBlend>
                      </filter>
                    </defs>
                    <g filter="url(#6waepprpsa)">
                      <path
                        fill-rule="evenodd"
                        fill="#97144D"
                        d="M10.928 9.513A6.022 6.022 0 0 0 1.116 2.53C-.812 5.24-.179 8.1 2.531 10.928a6.021 6.021 0 0 0 6.982 0l2.83 2.83a1.004 1.004 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.03a4.015 4.015 0 1 1 6.855 2.841A4.015 4.015 0 0 1 2.006 6.03z"
                      ></path>
                    </g>
                  </svg>
                </span>
                <input
                  type="text"
                  autoComplete={"off"}
                  id="myTextField"
                  tabindex="1"
                  placeholder={
                    !commonFunction.isValueEmpty(props.placholder)
                      ? props.placholder
                      : ""
                  }
                  className={props.styles.search_widget_input_new}
                  value={
                    !commonFunction.isValueEmpty(props.value) ? props.value : ""
                  }
                  onChange={(event) => {
                    props.onChangeSourceAndDestination(
                      event.target.value.replace(/[^a-zA-Z\s]/g, "")
                    );
                  }}
                  onClick={onClickHandler}
                  onFocusCapture={onClickHandler}
                  autoFocus
                />
              </div>
            )}
            <div className={props.styles.flights_city_parent}>
              {props?.isMobile ? (
                <div className={props.styles.headerAuto}>
                  <span
                    onClick={() => {
                      document.querySelector("body").style.overflow =
                        !props.expandAutoCompleteBool && props?.isMobile
                          ? "hidden"
                          : "auto";
                      props.popupClosed();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="24"
                    >
                      <path
                        fill-rule="evenodd"
                        fill="#FFF"
                        d="m13.444 3.241-8.815 8.747 8.817 8.749c.75.744.75 1.95 0 2.694a1.93 1.93 0 0 1-2.715 0L.566 13.343a1.89 1.89 0 0 1-.528-.99 1.891 1.891 0 0 1 .525-1.718L10.729.546a1.932 1.932 0 0 1 2.715 0 1.897 1.897 0 0 1 0 2.695z"
                      />
                    </svg>
                  </span>
                  {props?.Header}
                </div>
              ) : null}
              {props?.isMobile && (
                <div className={props.styles.flight_InputVal}>
                  <span className={props.styles.search_Autoicon}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14"
                    >
                      <defs>
                        <filter id="6waepprpsa">
                          <feFlood
                            flood-color="#979797"
                            flood-opacity="1"
                            result="floodOut"
                          ></feFlood>
                          <feComposite
                            operator="atop"
                            in="floodOut"
                            in2="SourceGraphic"
                            result="compOut"
                          ></feComposite>
                          <feBlend in="compOut" in2="SourceGraphic"></feBlend>
                        </filter>
                      </defs>
                      <g filter="url(#6waepprpsa)">
                        <path
                          fill-rule="evenodd"
                          fill="#97144D"
                          d="M10.928 9.513A6.022 6.022 0 0 0 1.116 2.53C-.812 5.24-.179 8.1 2.531 10.928a6.021 6.021 0 0 0 6.982 0l2.83 2.83a1.004 1.004 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.03a4.015 4.015 0 1 1 6.855 2.841A4.015 4.015 0 0 1 2.006 6.03z"
                        ></path>
                      </g>
                    </svg>
                  </span>
                  <input
                    type="text"
                    autoComplete={"off"}
                    id="myTextField"
                    tabindex="1"
                    placeholder={
                      !commonFunction.isValueEmpty(props.placholder)
                        ? props.placholder
                        : ""
                    }
                    className={props.styles.search_widget_input_new}
                    value={
                      !commonFunction.isValueEmpty(props.value)
                        ? props.value
                        : ""
                    }
                    onChange={(event) => {
                      props.onChangeSourceAndDestination(
                        event.target.value.replace(/[^a-zA-Z\s]/g, "")
                      );
                    }}
                    onClick={onClickHandler}
                    onFocusCapture={onClickHandler}
                    autoFocus
                  />
                </div>
              )}
              {/* {props.autoCompleteData.length > 0 ? (
                <p className={props.styles.suggestion_label}>Suggestions</p>
              ) : null} */}
              <p className={props.styles.suggestion_label}>Suggestions</p>

              {!commonFunction.isValueEmpty(props.autoCompleteData) &&
              props.autoCompleteData.length > 0 ? (
                props.autoCompleteData.map((element, i) => {
                  return (
                    <div className={props.styles.flights_city_main}>
                      {/* <svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" style="
"><path fill="currentColor" d="M4.108,11.369l-2.693,1.089l5.507,4.903l8.443-2.754l-2.087,6.658l3.571-1.454l3.358-6.778l6.325-2.058l0,0 c0.702-0.193,1.292-0.669,1.631-1.314c0.34-0.645,0.396-1.401,0.157-2.089c-0.239-0.688-0.753-1.246-1.419-1.542 c-0.665-0.295-1.424-0.302-2.094-0.018l-6.174,2.27L10.01,4.621L6.409,6.073l6.778,4.54l-5.628,2.452L4.108,11.369z M16.425,19.358 l-2.179,0.876l1.846-5.839l3.328-1.089L16.425,19.358z M7.709,6.194l2.3-0.938l8.595,3.692l6.415-2.361l0,0 c0.519-0.235,1.112-0.241,1.635-0.017c0.524,0.224,0.929,0.658,1.116,1.195c0.188,0.538,0.141,1.129-0.129,1.63 c-0.271,0.501-0.739,0.866-1.291,1.004L7.075,16.694L2.536,12.67l1.543-0.636l3.45,1.725l6.929-3.025L7.709,6.194z"></path><rect x="9.625" y="23.5" fill="currentColor" width="18.841" height="0.75"></rect></svg> */}
                      <div
                        id="flightData"
                        key={"autoCompleteData" + (i + 1)}
                        className={props.styles.flight_autocomplete_element}
                        onClick={() => {
                          selectListClickHandler(element);
                        }}
                        tabIndex="2"
                      >
                        <div className={props.styles.fltg_icon}>
                          <svg
                            className={props.styles.plane_img}
                            width="38"
                            height="27"
                          >
                            <path
                              fill-rule="evenodd"
                              stroke="#4B4B4B"
                              fill="#E4E4E4"
                              d="m29.85 4.74-7.135 3.081L15.65 6.14a1.495 1.495 0 0 0-.949.105l-1.652.806c-.492.245-.808.735-.773 1.261 0 .561.316 1.016.808 1.26l3.374 1.541-5.377 2.733-4.464-1.541a1.839 1.839 0 0 0-1.3.07c-.528.245-.914.77-.984 1.331-.071.595.14 1.156.597 1.541l4.358 3.642c.387.316.914.42 1.406.245l8.436-3.081-1.547 5.007c-.211.666 0 1.402.527 1.857.317.28.738.42 1.125.42.246 0 .527-.07.773-.174l1.371-.666c.351-.175.633-.456.808-.806l3.937-8.231 6.467-3.011a3.183 3.183 0 0 0 1.617-1.822c.281-.806.211-1.646-.141-2.416A3.184 3.184 0 0 0 29.85 4.74z"
                            ></path>
                          </svg>
                        </div>
                        <div className={props.styles.flight_autocomplete_name}>
                          <div className={props.styles.flight_cityCountTitle}>
                            <span>{element.CITY_NAME}</span>
                            <span>, </span>
                            <span>{element.COUNTRY_NAME}</span>
                          </div>
                          <span className={props.styles.flight_autoCityfull}>
                            {element.AIRPORT_NAME}
                          </span>
                        </div>
                        <div className={props.styles.flight_autocomplete_tag}>
                          {element.AIRPORT_CODE}
                        </div>

                        <div className={props.styles.flight_country}>
                          {element?.COUNTRY_CODE}
                        </div>

                        <div className={props.styles.flight_autoIcon}>
                          {element?.COUNTRY_FLAG ? (
                            <img
                              src={element?.COUNTRY_FLAG}
                              alt="country-icon"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div class={props.styles.no_result}>
                  {/* {props.isMobile ? (
                    <div className={props.styles.no_result_mobile}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="22px">
                        <path fill-rule="evenodd" stroke-width="1px" stroke="rgb(0, 0, 0)" fill="rgb(255, 255, 255)" d="M23.029,3.739 L17.573,6.184 L12.172,4.850 C11.930,4.794 11.688,4.822 11.446,4.933 L10.183,5.572 C9.807,5.767 9.565,6.156 9.592,6.573 C9.592,7.018 9.834,7.379 10.210,7.573 L12.790,8.796 L8.678,10.963 L5.265,9.741 C4.942,9.630 4.566,9.657 4.271,9.796 C3.867,9.991 3.572,10.408 3.518,10.853 C3.464,11.325 3.626,11.769 3.975,12.075 L7.307,14.965 C7.603,15.216 8.006,15.298 8.382,15.160 L14.832,12.714 L13.650,16.688 C13.489,17.216 13.650,17.800 14.053,18.161 C14.295,18.383 14.617,18.494 14.913,18.494 C15.101,18.494 15.316,18.438 15.504,18.356 L16.552,17.828 C16.821,17.689 17.036,17.466 17.170,17.188 L20.180,10.658 L25.125,8.268 C25.717,7.962 26.147,7.461 26.361,6.823 C26.576,6.184 26.523,5.517 26.254,4.906 C25.663,3.655 24.211,3.155 23.029,3.739 Z"/>
                      </svg>
                      <p className={props.styles.no_resMsg}>No Result Found</p>
                    </div>
                  ) : (
                    <> */}
                  <p className={props.styles.no_resMsg}>No Result Found</p>
                  {props.isMobile && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30px"
                      height="22px"
                    >
                      <path
                        fill-rule="evenodd"
                        stroke-width="1px"
                        stroke="rgb(0, 0, 0)"
                        fill="rgb(255, 255, 255)"
                        d="M23.029,3.739 L17.573,6.184 L12.172,4.850 C11.930,4.794 11.688,4.822 11.446,4.933 L10.183,5.572 C9.807,5.767 9.565,6.156 9.592,6.573 C9.592,7.018 9.834,7.379 10.210,7.573 L12.790,8.796 L8.678,10.963 L5.265,9.741 C4.942,9.630 4.566,9.657 4.271,9.796 C3.867,9.991 3.572,10.408 3.518,10.853 C3.464,11.325 3.626,11.769 3.975,12.075 L7.307,14.965 C7.603,15.216 8.006,15.298 8.382,15.160 L14.832,12.714 L13.650,16.688 C13.489,17.216 13.650,17.800 14.053,18.161 C14.295,18.383 14.617,18.494 14.913,18.494 C15.101,18.494 15.316,18.438 15.504,18.356 L16.552,17.828 C16.821,17.689 17.036,17.466 17.170,17.188 L20.180,10.658 L25.125,8.268 C25.717,7.962 26.147,7.461 26.361,6.823 C26.576,6.184 26.523,5.517 26.254,4.906 C25.663,3.655 24.211,3.155 23.029,3.739 Z"
                      />
                    </svg>
                  )}
                  {/* </>
                )} */}
                </div>
              )}

              {/* resent search */}

              {props.recentData.length && props.recentData ? (
                <>
                  <p className={props.styles.suggestion_label}>Recent Search</p>

                  {props.recentData.length &&
                    props.recentData?.map((element, i) => {
                      console.log(
                        "🚀 ~ file: FlightAutoComplete.js:141 ~ props.autoCompleteData.map ~ element:",
                        element
                      );
                      return (
                        <div className={props.styles.flights_city_main}>
                          {/* <svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" style="
"><path fill="currentColor" d="M4.108,11.369l-2.693,1.089l5.507,4.903l8.443-2.754l-2.087,6.658l3.571-1.454l3.358-6.778l6.325-2.058l0,0 c0.702-0.193,1.292-0.669,1.631-1.314c0.34-0.645,0.396-1.401,0.157-2.089c-0.239-0.688-0.753-1.246-1.419-1.542 c-0.665-0.295-1.424-0.302-2.094-0.018l-6.174,2.27L10.01,4.621L6.409,6.073l6.778,4.54l-5.628,2.452L4.108,11.369z M16.425,19.358 l-2.179,0.876l1.846-5.839l3.328-1.089L16.425,19.358z M7.709,6.194l2.3-0.938l8.595,3.692l6.415-2.361l0,0 c0.519-0.235,1.112-0.241,1.635-0.017c0.524,0.224,0.929,0.658,1.116,1.195c0.188,0.538,0.141,1.129-0.129,1.63 c-0.271,0.501-0.739,0.866-1.291,1.004L7.075,16.694L2.536,12.67l1.543-0.636l3.45,1.725l6.929-3.025L7.709,6.194z"></path><rect x="9.625" y="23.5" fill="currentColor" width="18.841" height="0.75"></rect></svg> */}
                          <div
                            id="flightData"
                            key={"autoCompleteData" + (i + 1)}
                            className={props.styles.flight_autocomplete_element}
                            onClick={() => {
                              selectListClickHandler(element);
                            }}
                            tabIndex="2"
                          >
                            <div className={props.styles.fltg_icon}>
                              <svg
                                className={props.styles.plane_img}
                                width="38"
                                height="27"
                              >
                                <path
                                  fill-rule="evenodd"
                                  stroke="#4B4B4B"
                                  fill="#E4E4E4"
                                  d="m29.85 4.74-7.135 3.081L15.65 6.14a1.495 1.495 0 0 0-.949.105l-1.652.806c-.492.245-.808.735-.773 1.261 0 .561.316 1.016.808 1.26l3.374 1.541-5.377 2.733-4.464-1.541a1.839 1.839 0 0 0-1.3.07c-.528.245-.914.77-.984 1.331-.071.595.14 1.156.597 1.541l4.358 3.642c.387.316.914.42 1.406.245l8.436-3.081-1.547 5.007c-.211.666 0 1.402.527 1.857.317.28.738.42 1.125.42.246 0 .527-.07.773-.174l1.371-.666c.351-.175.633-.456.808-.806l3.937-8.231 6.467-3.011a3.183 3.183 0 0 0 1.617-1.822c.281-.806.211-1.646-.141-2.416A3.184 3.184 0 0 0 29.85 4.74z"
                                ></path>
                              </svg>
                              {/* <svg xmlns="http://www.w3.org/2000/svg" width="17" height="22">
                                                        <path fill-rule="evenodd" d="m14.769 13.529-6.191 8.098c-.06.061-.151.121-.241.121a.275.275 0 0 1-.242-.121l-6.19-8.098a8.116 8.116 0 0 1 0-9.82C3.445 1.685 5.8.536 8.337.536c2.536 0 4.892 1.149 6.432 3.173a8.119 8.119 0 0 1 0 9.82zm-.483-9.457a7.482 7.482 0 0 0-5.949-2.931c-2.356 0-4.53 1.058-5.949 2.931a7.485 7.485 0 0 0 0 9.095l5.949 7.765 5.949-7.765a7.485 7.485 0 0 0 0-9.095zm-5.949 9.397c-2.658 0-4.832-2.175-4.832-4.835 0-2.659 2.174-4.834 4.832-4.834 2.657 0 4.831 2.175 4.831 4.834 0 2.66-2.174 4.835-4.831 4.835zm0-9.065a4.241 4.241 0 0 0-4.228 4.23 4.242 4.242 0 0 0 4.228 4.231 4.242 4.242 0 0 0 4.228-4.231 4.241 4.241 0 0 0-4.228-4.23z" />
                                                    </svg> */}
                            </div>
                            <div
                              className={props.styles.flight_autocomplete_name}
                            >
                              <div
                                className={props.styles.flight_cityCountTitle}
                              >
                                {element.CITY_NAME}, {element.COUNTRY_NAME}
                              </div>
                              {/* <div className={props.styles.flight_autocomplete_tag}>({element.AIRPORT_CODE})</div> */}
                              <span
                                className={props.styles.flight_autoCityfull}
                              >
                                {element.AIRPORT_NAME}
                              </span>
                            </div>
                            <div
                              className={props.styles.flight_autocomplete_tag}
                            >
                              {element.AIRPORT_CODE}
                            </div>

                            <div className={props.styles.flight_country}>
                              {element?.COUNTRY_CODE}
                            </div>

                            <div className={props.styles.flight_autoIcon}>
                              {element?.COUNTRY_FLAG ? (
                                <img
                                  src={element?.COUNTRY_FLAG}
                                  alt="country-icon"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
